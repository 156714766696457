<template>
  <v-container fluid>
    <Mentor />
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Mentor from '@/components/Mentor.vue'

export default defineComponent({
  components: { Mentor },
})
</script>
